<template>
  <div class="BestPlan">
    <section class="section container top-section">
      <h1 class="title is-2 has-text-white mb-1" v-html="$t('landing.best_plan')"/>
      <p class="has-text-white" v-html="$t('landing.best_plan_text')"/>
      <b-button v-if="!$root.isMobile()" tag="router-link" :to="'/signup?plan='+plan" class="mt-5 mb-5" type="is-primary" size="is-large" :label="$t('account.signup')"/>
    </section>

    <section class="section container">
      <div class="columns is-vcentered is-reversed-mobile">
        <div class="column">
          <div class="box has-text-left p-5">
            <h4 class="title is-5 mb-2">{{ $t('landing.why_choose_plan')}}</h4>
            <p v-html="$t('landing.best_'+plan+'1')"/>

            <h4 class="title is-5 mb-2 mt-5">{{ $t('landing.what_you_need')}}</h4>
            <div class="content">
              <ul>
                <li v-for="(text, i) in $t('landing.best_' + plan + '2')" :key="i">{{ text }}</li>
              </ul>
            </div>

            <h4 class="title is-5 mb-2 mt-5">{{ $t('landing.advantages')}}</h4>
            <p v-html="$t('landing.best_' + plan + '3')"/>

            <b-button tag="router-link" :to="'/signup?plan='+plan" class="mt-5 mb-5" type="is-primary" size="is-large" :label="$t('account.signup')"/>

          </div>
        </div>

        <div class="column has-background-secondary">
          <FeaturesList :showFree="plan === 'free'" :showStandard="plan === 'standard'" :showPremium="plan==='premium'"/>
          <b-button tag="router-link" to="/pricing" type="is-primary">{{ $t('landing.see_other_plans') }}</b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FeaturesList from './FeaturesList.vue';


export default{
  name: 'BestPlan',
  components: {
    FeaturesList,
  },
  computed: {
    plan(){
      const plans = {
        Competitive: { 2: 'standard', 4: 'premium', 6: 'premium' },
        Fondo: { 2: 'standard', 4: 'standard', 6: 'premium' },
        Fitness: { 2: 'free', 4: 'free', 6: 'standard' },
        Improve: { 2: 'free', 4: 'standard', 6: 'premium' },
      }
      let type = this.$route.query.type;
      let nbRides = parseInt(this.$route.query.nb_rides);
      return plans[type][nbRides] || 'free';
    },
  },
}
</script>

<style lang="scss" scoped>
.top-section{
  background-image: url("~@/assets/landing/BestPlan.png");
  max-width: 1240px !important;
  max-height: 311px !important;
}
</style>
